import React, { useRef, useEffect, useState } from 'react';
import './Contact.css';
import { send } from 'emailjs-com';
import { storage } from '../../config/firebase';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import Footer from '../Footer/Footer'; // Import Footer

function Contact() {
  const canvasRef = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isDrawing, setIsDrawing] = useState(true);
  const [markerColor, setMarkerColor] = useState('#000000');
  const [showColorPalette, setShowColorPalette] = useState(false);

  const markerCursor = `url('data:image/svg+xml;base64,${btoa(
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="M18.5 1.15c-.53 0-1.04.19-1.43.58l-5.81 5.82l5.65 5.65l5.82-5.81c.77-.78.77-2.04 0-2.83l-2.84-2.83c-.39-.39-.89-.58-1.39-.58M10.3 8.5l-5.96 5.96c-.78.78-.78 2.04.02 2.85C3.14 18.54 1.9 19.77.67 21h5.66l.86-.86c.78.76 2.03.75 2.81-.02l5.95-5.96"/></svg>'
  )}') 0 12, auto`;

  const eraserCursor = `url('data:image/svg+xml;base64,${btoa(
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="m16.24 3.56l4.95 4.94c.78.79.78 2.05 0 2.84L12 20.53a4.01 4.01 0 0 1-5.66 0L2.81 17c-.78-.79-.78-2.05 0-2.84l10.6-10.6c.79-.78 2.05-.78 2.83 0M4.22 15.58l3.54 3.53c.78.79 2.04.79 2.83 0l3.53-3.53l-4.95-4.95z"/></svg>'
  )}') 0 12, auto`;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let drawing = false;
  
    // Set the cursor to marker or eraser
    canvas.style.cursor = isDrawing ? markerCursor : eraserCursor;
  
    const getMousePosition = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      return {
        x: (e.clientX - rect.left) * scaleX,
        y: (e.clientY - rect.top) * scaleY,
      };
    };
  
    const getTouchPosition = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const touch = e.touches[0];
      return {
        x: (touch.clientX - rect.left) * scaleX,
        y: (touch.clientY - rect.top) * scaleY,
      };
    };
  
    const startDrawing = (e) => {
      e.preventDefault();
      drawing = true;
      const { x, y } = e.touches ? getTouchPosition(e) : getMousePosition(e);
      ctx.beginPath();
      ctx.moveTo(x, y);
    };
  
    const draw = (e) => {
      if (!drawing) return;
      e.preventDefault();
      const { x, y } = e.touches ? getTouchPosition(e) : getMousePosition(e);
      ctx.lineTo(x, y);
      ctx.strokeStyle = isDrawing ? markerColor : '#FFFFFF'; // If eraser, use white color
      ctx.lineWidth = 5;
      ctx.lineJoin = 'round';
      ctx.lineCap = 'round';
      ctx.stroke();
    };
  
    const stopDrawing = (e) => {
      e.preventDefault();
      drawing = false;
      ctx.closePath();
    };
  
    // Prevent default only when touching the canvas
    const preventTouchScroll = (e) => {
      if (drawing && e.target === canvas) {
        e.preventDefault();
      }
    };
  
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing);
  
    canvas.addEventListener('touchstart', startDrawing, { passive: false });
    canvas.addEventListener('touchmove', draw, { passive: false });
    canvas.addEventListener('touchend', stopDrawing, { passive: false });
    canvas.addEventListener('touchcancel', stopDrawing, { passive: false });
  
    // Use the preventTouchScroll function only for canvas interaction
    canvas.addEventListener('touchmove', preventTouchScroll, { passive: false });
  
    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseleave', stopDrawing);
  
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchmove', draw);
      canvas.removeEventListener('touchend', stopDrawing);
      canvas.removeEventListener('touchcancel', stopDrawing);
  
      canvas.removeEventListener('touchmove', preventTouchScroll);
    };
  }, [isDrawing, markerColor, markerCursor, eraserCursor]);  

  const uploadImageToFirebase = async (image) => {
    try {
      const imageRef = ref(storage, `drawings/${uuidv4()}.png`);
      await uploadString(imageRef, image, 'data_url');
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image to Firebase:', error);
      return null;
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png', 0.5);

    const imageUrl = await uploadImageToFirebase(image);
    if (!imageUrl) {
      alert('Failed to upload drawing. Please try again later.');
      return;
    }

    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID_SEND;
    const templateIDReceipt = process.env.REACT_APP_TEMPLATE_ID_RECEIPT;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const templateParams = {
      name,
      email,
      message,
      image_url: imageUrl,
    };

    const receiptParams = {
      user_name: name,
      user_email: email,
      message,
      image_url: imageUrl,
    };

    send(serviceID, templateID, templateParams, publicKey)
      .then(() => {
        alert('Message sent successfully with your drawing attached!');
        setName('');
        setEmail('');
        setMessage('');
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        send(serviceID, templateIDReceipt, receiptParams, publicKey)
          .then(() => {
            console.log('Receipt email sent successfully!');
          })
          .catch((err) => {
            console.error('Failed to send receipt email:', err);
          });
      })
      .catch((err) => {
        alert(`Failed to send message. Error: ${err.text}`);
      });
  };

  const renderColorPalette = () => (
    <div className="color-palette">
      {['#000000', '#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#8B00FF']
        .map((color) => (
          <div
            key={color}
            className="color-box"
            style={{ backgroundColor: color }}
            onClick={() => {
              setMarkerColor(color);
              setIsDrawing(true);
              setShowColorPalette(false);
            }}
          />
        ))}
    </div>
  );

  const resetCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <div className="contact-container">
      <div className="contact">
        <div className="contact-right">
          <h2>Send me a Note!</h2>
          <form className="contact-form" onSubmit={sendEmail}>
            <input
              type="text"
              name="name"
              placeholder="Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <textarea
              name="message"
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button type="submit">Send</button>
            <small className="form-subtitle">Your message will be sent to my email, nathanlin99@gmail.com</small>
          </form>
        </div>

        <div className="contact-left">
          <div className="canvas-container">
            <div className="canvas-wrapper">
              <canvas ref={canvasRef} width={300} height={300} className="contact-canvas"></canvas>
              <div className="contact-controls">
                <button onClick={() => setShowColorPalette(!showColorPalette)}>Marker</button>
                <button onClick={() => setIsDrawing(false)}>Eraser</button>
                <button onClick={resetCanvas}>Clear Canvas</button>
              </div>
            </div>
            {showColorPalette && renderColorPalette()}
          </div>
        </div>
      </div>

      {/* Footer should go here, at the bottom */}
      <Footer />
    </div>
  );
}

export default Contact;
