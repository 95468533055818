import React from 'react';
import './Footer.css';
import linkedinLogo from '../../assets/linkedin.png'; // Import LinkedIn logo
import githubLogo from '../../assets/github.png'; // Import GitHub logo

const Footer = () => {
  const handleSmoothScroll = (sectionId) => {
    document.querySelector(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <footer>
      <div className="footer-top-line"></div> {/* Static top black line */}
      <div className="footer-columns">
        {/* First Column: Links to different parts of the page */}
        <div className="footer-column">
          <h3>Navigation</h3>
          <ul>
            <li>
              <button className="footer-sticky-note" onClick={() => handleSmoothScroll('#homepage')}>
                Home
              </button>
            </li>
            <li>
              <button className="footer-sticky-note" onClick={() => handleSmoothScroll('#about')}>
                About
              </button>
            </li>
            <li>
              <button className="footer-sticky-note" onClick={() => handleSmoothScroll('#skills')}>
                Skills
              </button>
            </li>
            <li>
              <button className="footer-sticky-note" onClick={() => handleSmoothScroll('#works')}>
                Works
              </button>
            </li>
            <li>
              <button className="footer-sticky-note" onClick={() => handleSmoothScroll('#contact')}>
                Contact
              </button>
            </li>
          </ul>
        </div>

        {/* Second Column: Social Links */}
        <div className="footer-column">
          <h3>Socials</h3>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/nathan-lin8/" className="footer-sticky-note">
                LinkedIn
                <img src={linkedinLogo} alt="LinkedIn" className="social-logo" />
              </a>
            </li>
            <li>
              <a href="https://github.com/Nathan-Lin8" className="footer-sticky-note">
                GitHub
                <img src={githubLogo} alt="GitHub" className="social-logo" />
              </a>
            </li>
          </ul>
          <p className="footer-message">"Drawn" with my ideas :)</p>
          <p className="footer-copyright">© 2024 Nathan Lin</p>
        </div>

        {/* Button to jump to the homepage */}
        <div className="footer-top-button">
          <button onClick={() => handleSmoothScroll('#homepage')}></button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
