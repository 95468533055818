import React, { useState, useEffect } from 'react';
import { Cloud, renderSimpleIcon, fetchSimpleIcons } from 'react-icon-cloud';
import './Skills.css';

// Custom hook to load icons
const useIcons = (slugs, iconSize = 42) => {
  const [icons, setIcons] = useState(null);

  useEffect(() => {
    fetchSimpleIcons({ slugs }).then(setIcons);
  }, [slugs]);

  if (icons) {
    return Object.values(icons.simpleIcons).map((icon) =>
      renderSimpleIcon({
        icon,
        size: iconSize,
        aProps: {
          title: icon.title, // Use the title attribute for hover tooltip
          onClick: (e) => e.preventDefault(), // Prevents default link behavior
        },
      })
    );
  }
};

const slugs = [
  'python', 'c', 'mongodb', 'influxdb', 'homeassistant', 'git', 'github',
  'javascript', 'html5', 'css3', 'react', 'nextdotjs', 'node-dot-js', 'express',
  'bootstrap', 'vue-dot-js', 'figma', 'adobeillustrator', 'adobephotoshop',
  'tailwindcss', 'pytorch', 'tensorflow', 'flask', 'microsoftoffice',
];

const cloudProps = {
  containerProps: {
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  options: {
    reverse: true,
    depth: 1,
    wheelZoom: false,
    activeCursor: 'default',
    tooltip: 'native',
    initial: [0.1, -0.1],
    clickToFront: 500,
    tooltipDelay: 0,
    outlineColour: '#0000',
  },
};

function Skills() {
  const [isMobile, setIsMobile] = useState(false);
  const icons = useIcons(slugs, 64);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Check if screen width is <= 768px
    };

    // Check screen size on initial render and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on unmount
    };
  }, []);

  return (
    <div className="skills" id="skills">
      {isMobile ? (
        // For mobile screens, render Title -> Icon Cloud -> Description
        <>
          <h2 className="skills-title">My Skills</h2>
          <div className="skills-icon-cloud">
            <Cloud {...cloudProps} className="icon-cloud">
              {icons}
            </Cloud>
          </div>
          <p className="skills-note">(Click on icons to interact)</p> {/* Add this */}
          <p className="skills-description">
            I specialize in building responsive and interactive web applications, collaborating with UI/UX teams in <strong>Figma</strong> to design seamless user experiences. Using <strong>JavaScript</strong>, <strong>React</strong>, <strong>Vue</strong>, and <strong>CSS</strong> frameworks like <strong>Bootstrap</strong> and <strong>TailwindCSS</strong>, I bring designs to life. On the <strong>backend</strong>, I develop scalable applications with <strong>Node.js</strong> and <strong>Flask</strong>, and manage data using <strong>MongoDB</strong> and <strong>InfluxDB</strong> for optimized performance.
          </p>
        </>
      ) : (
        // For larger screens, render Icon Cloud -> Text (Title + Description)
        <>
          <div className="skills-left">
            <div className="skills-icon-container">
              <div className="skills-icon-cloud">
                <Cloud {...cloudProps} className="icon-cloud">
                  {icons}
                </Cloud>
              </div>
              <p className="skills-note">(Click on icons to interact)</p> {/* Moved inside skills-icon-container */}
            </div>
          </div>
          <div className="skills-right">
            <h2 className="skills-title">My Skills</h2>
            <p className="skills-description">
              I have a strong foundation in <strong>frontend development</strong>, where I excel at transforming ideas into interactive and responsive web applications. Working closely with UI/UX teams in <strong> Figma</strong>, I design intuitive user interfaces with a focus on seamless user experiences. I bring these designs to life using technologies like <strong>JavaScript</strong>, <strong>React</strong>, <strong>Vue</strong>, and <strong>CSS</strong> frameworks such as <strong>Bootstrap</strong> and <strong>TailwindCSS</strong>.
            </p>
            <p className="skills-description">
              On the <strong>backend</strong>, I have experience building scalable and efficient server-side applications using <strong>Node.js</strong> and <strong>Flask</strong>. I work with both <strong>MongoDB</strong> and <strong>InfluxDB</strong> to handle large-scale data, ensuring optimized database performance.
            </p>
          </div>
        </>
      )}
    </div>
  );
}



export default Skills;
