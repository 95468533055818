import React, { useState, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import './About.css';
import ProfilePic from '../../assets/profile-pic.jpg'; // Import your profile picture
import Magnet from '../../assets/Magnet.jpg'; // Import the magnet image

function About() {
  const [isMobile, setIsMobile] = useState(false);
  const aboutSectionRef = useRef(null); // Reference to the about section
  const polaroidRef = useRef(null);     // Reference to the polaroid element
  const magnetRef = useRef(null);       // Reference to the magnet element

  useLayoutEffect(() => {
    // Register the MotionPathPlugin with GSAP
    gsap.registerPlugin(MotionPathPlugin);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Check if screen width is <= 768px
    };

    // Check screen size on initial render and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    const currentSection = aboutSectionRef.current; // Store the ref's current value

    // Function to run the animation
    const animatePolaroidAndMagnet = () => {
      const tl = gsap.timeline(); // Create a GSAP timeline

      // Animate polaroid sliding in
      tl.fromTo(
        polaroidRef.current,
        {
          rotation: -90, // Start rotated 90 degrees
          x: '-100vw',   // Start from off-screen to the left
          y: '30vh',     // Start near the bottom
          opacity: 0     // Initially invisible
        },
        {
          rotation: 0,   // Rotate back to 0 degrees (upright)
          x: '0vw',      // Fly into the final position (centered)
          y: '0vh',      // Settle into the final position
          opacity: 1,    // Fade in
          duration: 1.5, // Duration of the animation
          ease: 'power2.inOut',
          motionPath: {
            path: [
              { x: '-10vw', y: '0vh' },  // Curve back down near the center
              { x: '0vw', y: '-2vh' }    // End in the final position
            ],
            autoRotate: true,             // Rotate along the path
          }
        }
      )
      // Animate magnet falling into place after the polaroid slides in
      .fromTo(
        magnetRef.current,
        { y: '-50px', scale: 1.5, opacity: 0 }, // Adjust starting position of magnet
        { y: '0px', scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' } // Bring it smoothly into position
      );
    };

    // IntersectionObserver to trigger animation when the section is visible
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animatePolaroidAndMagnet();  // Trigger animation when section is in view
            observer.unobserve(currentSection); // Unobserve after the animation is triggered
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on unmount
      if (currentSection) {
        observer.unobserve(currentSection); // Clean up observer on unmount
      }
    };
  }, [isMobile]);

  return (
    <div className="about" id="about" ref={aboutSectionRef}>
      {isMobile ? (
        <>
          <h2 className="about-title">About Me</h2>
          <div className="about-polaroid" ref={polaroidRef} style={{ opacity: 0 }}>
            {/* Magnet Image on top */}
            <img src={Magnet} alt="Magnet" className="magnet-img" ref={magnetRef} />
            <img src={ProfilePic} alt="Profile" className="profile-pic" />
          </div>
          <p class="about-description">
  I am a 4th year Georgia Tech Computational Media student with a focus on 
  <strong> Full-Stack Development</strong>, <strong>Interaction Design</strong>, and <strong>AI</strong>. 
  Passionate about creating impactful technology, I design and build intuitive 
  solutions that address real-world challenges, and love the process of <strong>ideation 
  to reality</strong>. In my free time, I enjoy being active and playing tennis and volleyball!
</p>

        </>
      ) : (
        <>
          <div className="about-left">
            <div className="about-polaroid" ref={polaroidRef} style={{ opacity: 0 }}>
              {/* Magnet Image on top */}
              <img src={Magnet} alt="Magnet" className="magnet-img" ref={magnetRef} />
              <img src={ProfilePic} alt="Profile" className="profile-pic" />
            </div>
          </div>
          <div className="about-right">
            <h2 className="about-title">About Me</h2>
            <p class="about-description">
  I am a 4th year Georgia Tech Computational Media student with a focus on 
  <strong> Full-Stack Development</strong>, <strong>Interaction Design</strong>, and <strong>AI</strong>. 
  Passionate about creating impactful technology, I design and build intuitive 
  solutions that address real-world challenges, and love the process of <strong>ideation 
  to reality</strong>. In my free time, I enjoy being active and playing tennis and volleyball!
</p>

          </div>
        </>
      )}
    </div>
  );
}

export default About;
