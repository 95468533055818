import React, { useEffect, useState, useRef } from 'react';
import Homepage from './sections/HomePage/Homepage';
import About from './sections/About/About';
import Skills from './sections/Skills/Skills';
import Works from './sections/Works/Works';
import Contact from './sections/Contact/Contact';
import MenuStickyNote from './components/MenuStickyNote';

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState('#homepage');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const homepageRef = useRef(null);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const worksRef = useRef(null);
  const contactRef = useRef(null);

  const sectionRefs = [
    { id: 'homepage', ref: homepageRef },
    { id: 'about', ref: aboutRef },
    { id: 'skills', ref: skillsRef },
    { id: 'works', ref: worksRef },
    { id: 'contact', ref: contactRef },
  ];

  // Function to determine observer thresholds based on section and screen size
  const getObserverOptions = (sectionId) => {
    switch (sectionId) {
      case 'homepage':
        return { threshold: isMobile ? 0.3 : 0.5, rootMargin: '0px' };
      case 'about':
        return { threshold: isMobile ? 0.4 : 0.6, rootMargin: '0px' };
      case 'skills':
        return { threshold: isMobile ? 0.4 : 0.6, rootMargin: '0px' };
      case 'works':
        return { threshold: isMobile ? 0.01 : 0.2, rootMargin: '0px' };
      case 'contact':
        return { threshold: isMobile ? 0.1 : 0.3, rootMargin: '0px' };
      default:
        return { threshold: 0.5, rootMargin: '0px' };
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Create individual observers for each section
    const observers = sectionRefs.map(({ id, ref }) => {
      const observerOptions = getObserverOptions(id);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newActiveSection = `#${entry.target.id}`;
            if (newActiveSection !== activeSection) {
              setActiveSection(newActiveSection);
            }
          }
        });
      }, observerOptions);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return observer;
    });

    return () => {
      sectionRefs.forEach(({ ref }, index) => {
        if (ref.current) {
          observers[index].unobserve(ref.current);
        }
      });
    };
  }, [activeSection, isMobile]);

  useEffect(() => {
    const homepageObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setShowMenu(!entry.isIntersecting);
        });
      },
      { threshold: 0.5 }
    );

    if (homepageRef.current) {
      homepageObserver.observe(homepageRef.current);
    }

    return () => {
      if (homepageRef.current) {
        homepageObserver.unobserve(homepageRef.current);
      }
    };
  }, []);

  return (
    <div className={`app-container ${isMobile ? 'snap-disabled' : 'snap-enabled'}`}>
      <div id="homepage" className="section" ref={homepageRef}>
        <Homepage />
      </div>
      <div id="about" className="section" ref={aboutRef}>
        <About />
      </div>
      <div id="skills" className="section" ref={skillsRef}>
        <Skills />
      </div>
      <div id="works" className="section" ref={worksRef}>
        <Works />
      </div>
      <div id="contact" className="section" ref={contactRef}>
        <Contact />
      </div>

      {showMenu && <MenuStickyNote activeSection={activeSection} />}
    </div>
  );
}

export default App;
